import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, Typography, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import * as _ from 'lodash';

export default function DigitalSeriesDataPage({
  pageContext: { _digitalSeriesByYearArray, _year, _digitalSeriesYears },
}) {
  const currentYear = '2020';
  const [years, setYears] = useState(_digitalSeriesYears.sort((a, b) => b - a));
  const [hasResult] = useState(true);
  const [filteredDigitalSeries, setfilteredDigitalSeries] = useState(_digitalSeriesByYearArray);

  // useEffect(() => {
  //   const _years = Object.keys(DigitalSeriesData);
  //   setYears(_years.sort((a, b) => b - a));
  //   const findDigitalSeries = _.filter(DigitalSeriesData[currentYear], function (item) {
  //     return item;
  //   });
  //   setfilteredDigitalSeries(findDigitalSeries);
  //   return () => {};
  //   // eslint-disable-next-line
  // }, []);

  return (
    <Layout>
      <Box mt={10}>
        <SEO lang='en' title={`Digital Series | Global Manufacturing & Industrialisation Summit | GMIS${_year}`} />
        <SectionContainerLayoutWithFilter
          title={`DIGITAL SERIES ${_year}`}
          baseLink='/digital-series'
          filters={years}
          isViewAll
          isDivider>
          <Grid container direction='row' spacing={3}>
            {filteredDigitalSeries.map((_digitalSeries, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <CardImageTitleDescription
                  isImgTag
                  image={
                    _digitalSeries.file[0].type !== 'video/mp4'
                      ? _digitalSeries.file[0].code
                      : _digitalSeries.file[1].code
                  }
                  title={_digitalSeries.title}
                  body={_digitalSeries.description}
                  readMore={`/digital-series/${_digitalSeries.year}/${_digitalSeries.urlSlug}`}
                  lines={3}
                />
              </Grid>
            ))}
          </Grid>
        </SectionContainerLayoutWithFilter>
      </Box>
    </Layout>
  );
}
